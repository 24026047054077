import React from "react";
import ReactDOM from "react-dom";
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import ReactFullpage from "@fullpage/react-fullpage";
import "./styles.css";


const first_name = "Ray";
const last_name = "Naglieri";
const blurb = "Leveraging technology to bring people together."
const bio = "Originally From Orlando, FL with a background in computer science and leadership development, I'm currently exploring applications of programming that bring people together. As a result of the COVID-19 pandemic, my focus has shifted specifically towards video games and social media. This is because of their ability to support community connectedness and well-being.";
const project_1_title = "Mixed-Reality Integrated Learning Environment";
const project_1_description = "👨🏾‍🎓 The Mixed-Reality Integrated Learning Environment (MILE) developed at Florida State University is a virtual reality based, inclusive and immersive e-learning environment that promotes engaging and effective learning interactions for a diversified learner population. MILE uses a large number of interactive Non-Player Characters (NPCs) to represent diverse research-based learner archetypes and groups, and to prompt and provide feedback for in situ teaching practice.";
const project_2_title = "Wecken";
const project_2_description = " 🌄 Wecken is a simple pre-configured Restful API web-server, designed to quickly prototype APIs. Wecken is written in Python and built with uvicorn, FastAPI, and MongoDB. A Dockerfile is also included, to quickly build production ready images for any platform. Click the GitHub logo to view the code.";
const project_3_title = "Project Euler Solutions";
const project_3_description = "🧮 I'm currently chipping away at the first 100 problems on projecteuler.net. Project Euler is a series of challenging mathematical/computer programming problems. Completed solutions are colored and linked in red squares."


class App extends React.Component {

  onLeave(origin, destination, direction) {
    console.log("Leaving section " + origin.index);
  }

  afterLoad(origin, destination, direction) {
    console.log("After load: " + destination.index);
  }

  slideMount() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
    this.div.appendChild(script);
  }

  renderContentSec1 = (fullpageApi) => {
    return <div>
      <ul class="flex-container">
        <li class="header-flex-item-outline">{first_name} {last_name}</li>
        <li class="header-flex-item-bold">{first_name} {last_name}</li>
        <li class="header-flex-item-outline">{first_name} {last_name}</li>
        <li class="blurb-flex-item">{blurb}</li>
        <li class="header-navigation-flex-container" >

          <button onClick={() => fullpageApi.moveTo(2, 0)}>
            CODE.
          </button>
          <button onClick={() => fullpageApi.moveTo(3, 0)}>
            DESIGN.
          </button>
          <button onClick={() => fullpageApi.moveTo(4, 0)}>
            BIO.
          </button>
          <button onClick={() => fullpageApi.moveTo(5, 0)}>
            CONTACT.
          </button>
        </li>
      </ul>
    </div>
  }

  renderContentSec2 = (fullpageApi) => {

    return <div>
      <div className="slide">
        <ul className='projects-flex-container'>
          <li className="projects-flex-item-bold">{project_1_title}</li>
          <li className="projects-flex-item-outline">01 / 03</li>
          <li className="video-container">
            <iframe width="660" height="415" src="https://www.youtube.com/embed/rsDKs1ZjsgM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </li>
          <li className="projects-blurb-flex-item">{project_1_description}</li>
        </ul>
      </div>
      <div className="slide">
        <ul className='projects-flex-container'>
          <li className="projects-flex-item-bold">{project_2_title}</li>
          <li className="projects-flex-item-outline">02 / 03</li>
          <a className="projects-flex-item-github" href="https://github.com/raynaglieri/wecken_api"><span class="tooltiptext">View On Github</span></a>
          <li className="projects-blurb-flex-item">{project_2_description}</li>
        </ul>
      </div>
      <div className="slide">
        <ul className='projects-flex-container'>
          <li className="projects-flex-item-bold">{project_3_title}</li>
          <li className="projects-flex-item-outline">03 / 03</li>
          <li>
            <div class="container">
              <div class="grid">
                <a id="1" class="completed_cell" href="https://github.com/raynaglieri/project_euler/blob/master/problem_1.py">1<span class="tooltiptext">View on Github</span></a>
                <div class="cell" id="2">2 </div>
                <div class="cell" id="3">3</div>
                <div class="cell" id="4">4</div>
                <div class="cell" id="5">5</div>
                <div class="cell" id="6">6</div>
                <div class="cell" id="7">7</div>
                <div class="cell" id="8">8</div>
                <div class="cell" id="9">9</div>
                <div class="cell" id="10">10</div>
                <div class="cell" id="11">11</div>
                <div class="cell" id="12">12</div>
                <div class="cell" id="13">13</div>
                <div class="cell" id="14">14</div>
                <div class="cell" id="15">15</div>
                <div class="cell" id="16">16</div>
                <div class="cell" id="17">17</div>
                <div class="cell" id="18">18</div>
                <div class="cell" id="19">19</div>
                <div class="cell" id="20">20</div>
                <div class="cell" id="21">21</div>
                <div class="cell" id="22">22</div>
                <div class="cell" id="23">23</div>
                <div class="cell" id="24">24</div>
                <div class="cell" id="25">25</div>
                <div class="cell" id="26">26</div>
                <div class="cell" id="27">27</div>
                <div class="cell" id="28">28</div>
                <div class="cell" id="29">29</div>
                <div class="cell" id="30">30</div>
                <div class="cell" id="31">31</div>
                <div class="cell" id="32">32</div>
                <div class="cell" id="33">33</div>
                <div class="cell" id="34">34</div>
                <div class="cell" id="35">35</div>
                <div class="cell" id="36">36</div>
                <div class="cell" id="37">37</div>
                <div class="cell" id="38">38</div>
                <div class="cell" id="39">39</div>
                <div class="cell" id="40">40</div>
                <div class="cell" id="41">41</div>
                <div class="cell" id="42">42</div>
                <div class="cell" id="43">43</div>
                <div class="cell" id="44">44</div>
                <div class="cell" id="45">45</div>
                <div class="cell" id="46">46</div>
                <div class="cell" id="47">47</div>
                <div class="cell" id="48">48</div>
                <div class="cell" id="49">49</div>
                <div class="cell" id="50">50</div>
                <div class="cell" id="51">51</div>
                <div class="cell" id="52">52</div>
                <div class="cell" id="53">53</div>
                <div class="cell" id="54">54</div>
                <div class="cell" id="55">55</div>
                <div class="cell" id="56">56</div>
                <div class="cell" id="57">57</div>
                <div class="cell" id="58">58</div>
                <div class="cell" id="59">59</div>
                <div class="cell" id="60">61</div>
                <div class="cell" id="61">61</div>
                <div class="cell" id="62">62</div>
                <div class="cell" id="63">63</div>
                <div class="cell" id="64">64</div>
                <div class="cell" id="65">65</div>
                <div class="cell" id="66">66</div>
                <div class="cell" id="67">67</div>
                <div class="cell" id="68">68</div>
                <div class="cell" id="69">69</div>
                <div class="cell" id="70">70</div>
                <div class="cell" id="71">71</div>
                <div class="cell" id="72">72</div>
                <div class="cell" id="73">73</div>
                <div class="cell" id="74">74</div>
                <div class="cell" id="75">75</div>
                <div class="cell" id="76">76</div>
                <div class="cell" id="77">77</div>
                <div class="cell" id="78">78</div>
                <div class="cell" id="79">79</div>
                <div class="cell" id="80">80</div>
                <div class="cell" id="81">81</div>
                <div class="cell" id="82">82</div>
                <div class="cell" id="83">83</div>
                <div class="cell" id="84">84</div>
                <div class="cell" id="85">85</div>
                <div class="cell" id="86">86</div>
                <div class="cell" id="87">87</div>
                <div class="cell" id="88">88</div>
                <div class="cell" id="89">89</div>
                <div class="cell" id="90">90</div>
                <div class="cell" id="91">91</div>
                <div class="cell" id="92">92</div>
                <div class="cell" id="93">93</div>
                <div class="cell" id="94">94</div>
                <div class="cell" id="95">95</div>
                <div class="cell" id="96">96</div>
                <div class="cell" id="97">97</div>
                <div class="cell" id="98">98</div>
                <div class="cell" id="99">99</div>
                <div class="cell" id="100">100</div>
              </div>
            </div>
          </li>
          <li className="projects-blurb-flex-item">{project_3_description}</li>
        </ul>
      </div>
    </div>
  }

  renderContentSec3 = (fullpageApi) => {

    return <div>
      <ul class="flex-container">
        <li class="header-flex-item-outline">Design Portfolio</li>
        <li class="blurb-flex-item">Coming Soon.</li>
      </ul>
    </div>
  }

  renderContentSec4 = (fullpageApi) => {

    return <div>
      <ul className="bio-col-flex-container">
        <li className="bio-flex-item-bold">BIO:</li>
        <li className="bio-blurb-flex-item">{bio}</li>
        <li className="bio-flex-item-bold">Other Hobbies and Fun Facts:</li>
        <li className="bio-blurb-flex-item">🎒 Backpacked Central Europe</li>
        <li className="bio-blurb-flex-item">🏂 Snowboarded in the Swiss Alps</li>
        <li className="bio-blurb-flex-item">🍻 Celebrated Oktoberfest in Munich</li>
        <li className="bio-blurb-flex-item">🇨🇭 Worked in Zürich, Switzerland</li>
        <li className="bio-blurb-flex-item">🎓 Graduated from Florida State University</li>
        <li className="bio-blurb-flex-item">🎵 Learning Music Production</li>
        <li className="bio-blurb-flex-item">🎮 Competitive Call of Duty Player</li>
        <li className="bio-blurb-flex-item">🏒 Ice Hockey Player</li>
        <li className="bio-blurb-flex-item">🇩🇪 Learning German</li>
        <li className="bio-blurb-flex-item">🖌️ Graphic Designer</li>
        <li className="bio-blurb-flex-item">📷 Photographer </li>
      </ul>
    </div>
  }



  renderContentSec5 = (fullpageApi) => {

    return <div>
      <div className="footer-navigation-flex-container">
        <a class="footer-flex-item-github" href="https://github.com/raynaglieri"><span class="tooltiptext">View my Github Profile.</span></a>
        <a class="footer-flex-item-linkedin" href="https://www.linkedin.com/in/raymond-j-naglieri/"><span class="tooltiptext">View my linkedin Profile.</span></a>
        <a class="footer-flex-item-gmail" href="mailto: raymondnaglieri@gmail.com"><span class="tooltiptext">Email me.</span></a>
      </div>
    </div>
  }

  render() {
    const Menu = () => (
      <div
        className="menu"
        style={{
          position: 'fixed',
          top: 0,
          zIndex: 100,
        }}
      >
        <ul className="navbar-flex-container">
          <li>
            <button onClick={() => window.fullpage_api.moveTo(1, 0)}>
              Top.
            </button>
          </li>
        </ul>
      </div>
    );

    return (
      <div className="App">
        <Menu />
        <ReactFullpage
          licenseKey={'F9FADAA5-C07C48B4-9A3BEBFC-DF2FB765'}
          css3={true}
          scrollingSpeed={1000}
          scrollOverflow={true}
          sectionsColor={["#242424", "#242424", "#242424", "#242424", "#B51D4E"]}
          verticalCentered={false}
          paddingTop={'1em'}
          paddingBottom={'1em'}
          onLeave={this.onLeave.bind(this)}
          afterLoad={this.afterLoad.bind(this)}
          render={({ state, fullpageApi }) => {
            return (
              <div id="fullpage-wrapper">
                <div className="section section1">{this.renderContentSec1(fullpageApi)}</div>
                <div className="section section2">{this.renderContentSec2(fullpageApi)}</div>
                <div className="section section3">{this.renderContentSec3(fullpageApi)}</div>
                <div className="section section4">{this.renderContentSec4(fullpageApi)}</div>
                <div className="section fp-auto-height section5">{this.renderContentSec5(fullpageApi)}</div>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

export default App;
